<template>
  <div class="sankey-style" :id="secondMap ? 'chartdiv2' : 'chartdiv'"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default {
  name: 'Sankey',
  props: {
    data: {
      type: Array
    },
    secondMap: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (this.chart !== null){
        this.chart.dispose()
      }
      var self = this
      am4core.ready(function() {
        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        var container
        if (self.secondMap) {
          container = "chartdiv2"
        } else {
          container = "chartdiv"
        }

        self.chart = am4core.create(container, am4charts.SankeyDiagram);
        self.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        self.chart.data = []
          /* { from: "A", to: "D", value: 10 },
          { from: "B", to: "D", value: 8 },
          { from: "B", to: "E", value: 4 },
          { from: "C", to: "E", value: 3 },
          { from: "D", to: "G", value: 5 },
          { from: "D", to: "I", value: 2 },
          { from: "D", to: "H", value: 3 },
          { from: "E", to: "H", value: 6 },    
          { from: "G", to: "J", value: 5 },
          { from: "I", to: "J", value: 1 },
          { from: "H", to: "J", value: 9 } */
        self.data.forEach(function (val) {
          self.chart.data.push({ from: val.nom_stat_19, nodeColor: val.s_19_color })
          self.chart.data.push({ from: val.nom_stat_10, to: val.nom_stat_19, value: Math.round(val.s_evo), nodeColor: val.s_10_color })
        })
        self.chart.nodes.template.nameLabel.label.width = 150;
        self.chart.nodes.template.nameLabel.label.truncate = false;
        self.chart.nodes.template.nameLabel.label.wrap = true;

        let hoverState = self.chart.links.template.states.create("hover");
        hoverState.properties.fillOpacity = 0.6;

        self.chart.dataFields.fromName = "from";
        self.chart.dataFields.toName = "to";
        self.chart.dataFields.value = "value";
        self.chart.dataFields.color = "nodeColor";

        // for right-most label to fit
        self.chart.paddingRight = 30;

        // make nodes draggable
        var nodeTemplate = self.chart.nodes.template;
        nodeTemplate.readerTitle = "Click to show/hide or drag to rearrange";
        nodeTemplate.showSystemTooltip = true;
        nodeTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer

        nodeTemplate.nameLabel.adapter.add("locationX", function(location, target) {
          switch (target.parent.level) {
            case 1:
              return -13;
            default:
              return 1;
          }
        });

        }); // end am4core.ready()
    }
  },
  watch: {
    data () {
      this.init()
    }
  }
}
</script>

<style scoped>
.sankey-style {
  width: 105%;
  height: 700px
}
</style>