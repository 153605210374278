<template>
<!-- Friezes and graphs section -->
<v-container id="friezes">
  <v-expansion-panels accordion>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Artificialisation 
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <MultiFrieze
          :dates="[items.annee_n, items.annee_nm1]"
          :data="[
            [parseFloat(items.tx_19_1), parseFloat(items.tx_19_2)],
            [parseFloat(items10.tx_10_1), parseFloat(items10.tx_10_2)]
          ]"
          :tooltipData="[
            [parseFloat(items.s_19_1), parseFloat(items.s_19_2)],
            [parseFloat(items10.s_10_1), parseFloat(items10.s_10_2)]
          ]"
          :labels="[
            items.nom_stat_1 + ' (' + items.s_19_1 + ' ha en 2019) (' + items10.s_10_1 + ' ha en 2010)',
            items.nom_stat_2 + ' (' + items.s_19_2 + ' ha en 2019) (' + items10.s_10_2 + ' ha en 2010)'
          ]"
          :colors="[
            'red_maxime',
            'grey_maxime'
          ]"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Urbanisation 
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <MultiFrieze
          :dates="[itemsUrba.annee_n, itemsUrba.annee_nm1]"
          :data="[
            [parseFloat(itemsUrba.tx_19_1), parseFloat(itemsUrba.tx_19_2), parseFloat(itemsUrba.tx_19_3), parseFloat(itemsUrba.tx_19_4), parseFloat(itemsUrba.tx_19_5)],
            [parseFloat(itemsUrba10.tx_10_1), parseFloat(itemsUrba10.tx_10_2), parseFloat(itemsUrba10.tx_10_3), parseFloat(itemsUrba10.tx_10_4), parseFloat(itemsUrba10.tx_10_5)],
          ]"
          :tooltipData="[
            [parseFloat(itemsUrba.s_19_1), parseFloat(itemsUrba.s_19_2), parseFloat(itemsUrba.s_19_3), parseFloat(itemsUrba.s_19_4), parseFloat(itemsUrba.s_19_5)],
            [parseFloat(itemsUrba10.s_10_1), parseFloat(itemsUrba10.s_10_2), parseFloat(itemsUrba10.s_10_3), parseFloat(itemsUrba10.s_10_4), parseFloat(itemsUrba10.s_10_5)],
          ]"
          :labels="[
            itemsUrba.nom_stat_1 + ' (' + itemsUrba.s_19_1 + ' ha en 2019) (' + itemsUrba10.s_10_1 + ' ha en 2010)',
            itemsUrba.nom_stat_2 + ' (' + itemsUrba.s_19_2 + ' ha en 2019) (' + itemsUrba10.s_10_2 + ' ha en 2010)',
            itemsUrba.nom_stat_3 + ' (' + itemsUrba.s_19_3 + ' ha en 2019) (' + itemsUrba10.s_10_3 + ' ha en 2010)',
            itemsUrba.nom_stat_4 + ' (' + itemsUrba.s_19_4 + ' ha en 2019) (' + itemsUrba10.s_10_4 + ' ha en 2010)',
            itemsUrba.nom_stat_5 + ' (' + itemsUrba.s_19_5 + ' ha en 2019) (' + itemsUrba10.s_10_5 + ' ha en 2010)'
          ]"
          :colors="[
            'red_urba',
            'pink_urba',
            'purple_urba',
            'grey_urba1',
            'grey_maxime'  
          ]"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Habitat 
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <MultiFrieze
          :dates="[itemsHabitat.annee_n, itemsHabitat.annee_nm1]"
          :data="[
            [parseFloat(itemsHabitat.tx_19_1), parseFloat(itemsHabitat.tx_19_2), parseFloat(itemsHabitat.tx_19_3), parseFloat(itemsHabitat.tx_19_4), parseFloat(itemsHabitat.tx_19_5), parseFloat(itemsHabitat.tx_19_6)],
            [parseFloat(itemsHabitat10.tx_10_1), parseFloat(itemsHabitat10.tx_10_2), parseFloat(itemsHabitat10.tx_10_3), parseFloat(itemsHabitat10.tx_10_4), parseFloat(itemsHabitat10.tx_10_5), parseFloat(itemsHabitat10.tx_10_6)]
          ]"
          :tooltipData="[
            [parseFloat(itemsHabitat.s_19_1), parseFloat(itemsHabitat.s_19_2), parseFloat(itemsHabitat.s_19_3), parseFloat(itemsHabitat.s_19_4), parseFloat(itemsHabitat.s_19_5), parseFloat(itemsHabitat.s_19_6)],
            [parseFloat(itemsHabitat10.s_10_1), parseFloat(itemsHabitat10.s_10_2), parseFloat(itemsHabitat10.s_10_3), parseFloat(itemsHabitat10.s_10_4), parseFloat(itemsHabitat10.s_10_5), parseFloat(itemsHabitat10.s_10_6)]
          ]"
          :labels="[
            itemsHabitat.nom_stat_1 + ' (' + itemsHabitat.s_19_1 + ' ha en 2019) (' + itemsHabitat10.s_10_1 + ' ha en 2010)',
            itemsHabitat.nom_stat_2 + ' (' + itemsHabitat.s_19_2 + ' ha en 2019) (' + itemsHabitat10.s_10_2 + ' ha en 2010)',
            itemsHabitat.nom_stat_3 + ' (' + itemsHabitat.s_19_3 + ' ha en 2019) (' + itemsHabitat10.s_10_3 + ' ha en 2010)',
            itemsHabitat.nom_stat_4 + ' (' + itemsHabitat.s_19_4 + ' ha en 2019) (' + itemsHabitat10.s_10_4 + ' ha en 2010)',
            itemsHabitat.nom_stat_5 + ' (' + itemsHabitat.s_19_5 + ' ha en 2019) (' + itemsHabitat10.s_10_5 + ' ha en 2010)',
            itemsHabitat.nom_stat_6 + ' (' + itemsHabitat.s_19_6 + ' ha en 2019) (' + itemsHabitat10.s_10_6 + ' ha en 2010)'
          ]"
          :colors="[
            'red_habitat1',
            'orange_habitat1',
            'red_habitat2',
            'orange_habitat2',
            'red_habitat3',
            'yellow_habitat'
          ]"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Activité économique 
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <MultiFrieze
          :dates="[itemsActivites.annee_n, itemsActivites.annee_nm1]"
          :data="[
            [parseFloat(itemsActivites.tx_19_1), parseFloat(itemsActivites.tx_19_2), parseFloat(itemsActivites.tx_19_3), parseFloat(itemsActivites.tx_19_4), parseFloat(itemsActivites.tx_19_5), parseFloat(itemsActivites.tx_19_6), parseFloat(itemsActivites.tx_19_7)],
            [parseFloat(itemsActivites10.tx_10_1), parseFloat(itemsActivites10.tx_10_2), parseFloat(itemsActivites10.tx_10_3), parseFloat(itemsActivites10.tx_10_4), parseFloat(itemsActivites10.tx_10_5), parseFloat(itemsActivites10.tx_10_6), parseFloat(itemsActivites10.tx_10_7)]
          ]"
          :tooltipData="[
            [parseFloat(itemsActivites.s_19_1), parseFloat(itemsActivites.s_19_2), parseFloat(itemsActivites.s_19_3), parseFloat(itemsActivites.s_19_4), parseFloat(itemsActivites.s_19_5), parseFloat(itemsActivites.s_19_6), parseFloat(itemsActivites.s_19_7)],
            [parseFloat(itemsActivites10.s_10_1), parseFloat(itemsActivites10.s_10_2), parseFloat(itemsActivites10.s_10_3), parseFloat(itemsActivites10.s_10_4), parseFloat(itemsActivites10.s_10_5), parseFloat(itemsActivites10.s_10_6), parseFloat(itemsActivites10.s_10_7)]
          ]"
          :labels="[
            itemsActivites.nom_stat_1 + ' (' + itemsActivites.s_19_1 + ' ha en 2019) (' + itemsActivites10.s_10_1 + ' ha en 2010)',
            itemsActivites.nom_stat_2 + ' (' + itemsActivites.s_19_2 + ' ha en 2019) (' + itemsActivites10.s_10_2 + ' ha en 2010)',
            itemsActivites.nom_stat_3 + ' (' + itemsActivites.s_19_3 + ' ha en 2019) (' + itemsActivites10.s_10_3 + ' ha en 2010)',
            itemsActivites.nom_stat_4 + ' (' + itemsActivites.s_19_4 + ' ha en 2019) (' + itemsActivites10.s_10_4 + ' ha en 2010)',
            itemsActivites.nom_stat_5 + ' (' + itemsActivites.s_19_5 + ' ha en 2019) (' + itemsActivites10.s_10_5 + ' ha en 2010)',
            itemsActivites.nom_stat_6 + ' (' + itemsActivites.s_19_6 + ' ha en 2019) (' + itemsActivites10.s_10_6 + ' ha en 2010)',
            itemsActivites.nom_stat_7 + ' (' + itemsActivites.s_19_7 + ' ha en 2019) (' + itemsActivites10.s_10_7 + ' ha en 2010)'
          ]"
          :colors="[
            'purple_activity1',
            'purple_activity2',
            'purple_activity3',
            'maroon_activity1',
            'maroon_activity2',
            'purple_activity4',
            'red_activity'
          ]"
          :whiteText="true"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Perméabilité des sols 
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <v-container class="pb-0">
          <p class="mb-0" style="font-weight: bold">Habitat</p>
        </v-container>
        <MultiFrieze
          style="margin-bottom: 6px"
          :dates="[itemsPerma.annee_n, itemsPerma.annee_nm1]"
          :data="[
            [parseFloat(itemsPerma.tx_19_1), parseFloat(itemsPerma.tx_19_2), parseFloat(itemsPerma.tx_19_3)],
            [parseFloat(itemsPerma10.tx_10_1), parseFloat(itemsPerma10.tx_10_2), parseFloat(itemsPerma10.tx_10_3)]
          ]"
          :tooltipData="[
            [parseFloat(itemsPerma.s_19_1), parseFloat(itemsPerma.s_19_2), parseFloat(itemsPerma.s_19_3)],
            [parseFloat(itemsPerma10.s_10_1), parseFloat(itemsPerma10.s_10_2), parseFloat(itemsPerma10.s_10_3)]
          ]"
          :labels="[]"
          :colors="[
            'grey_perma1',
            'grey_perma2',
            'grey_perma3'
          ]"
          :whiteText="true"/>
        <v-container class="pb-0">
          <p class="mb-0" style="font-weight: bold">Equipements, infrastructures collectives et activités économiques</p>
        </v-container>
        <MultiFrieze
          style="margin-bottom: 6px"
          :dates="[itemsPerma.annee_n, itemsPerma.annee_nm1]"
          :data="[
            [parseFloat(itemsPerma.tx_19_4), parseFloat(itemsPerma.tx_19_5), parseFloat(itemsPerma.tx_19_6)],
            [parseFloat(itemsPerma10.tx_10_4), parseFloat(itemsPerma10.tx_10_5), parseFloat(itemsPerma10.tx_10_6)]
          ]"
          :tooltipData="[
            [parseFloat(itemsPerma.s_19_4), parseFloat(itemsPerma.s_19_5), parseFloat(itemsPerma.s_19_6)],
            [parseFloat(itemsPerma10.s_10_4), parseFloat(itemsPerma10.s_10_5), parseFloat(itemsPerma10.s_10_6)]
          ]"
          :labels="[]"
          :colors="[
            'grey_perma1',
            'grey_perma2',
            'grey_perma3'
          ]"
          :whiteText="true"/>
        <v-container class="pb-0">
          <p class="mb-0" style="font-weight: bold">Infrastructure et superstructures des réseaux de transports</p>
        </v-container>
        <MultiFrieze
          style="margin-bottom: 12px"
          :dates="[itemsPerma.annee_n, itemsPerma.annee_nm1]"
          :data="[
            [parseFloat(itemsPerma.tx_19_7), parseFloat(itemsPerma.tx_19_8), parseFloat(itemsPerma.tx_19_9)],
            [parseFloat(itemsPerma10.tx_10_7), parseFloat(itemsPerma10.tx_10_8), parseFloat(itemsPerma10.tx_10_9)]
          ]"
          :tooltipData="[
            [parseFloat(itemsPerma.s_19_7), parseFloat(itemsPerma.s_19_8), parseFloat(itemsPerma.s_19_9)],
            [parseFloat(itemsPerma10.s_10_7), parseFloat(itemsPerma10.s_10_8), parseFloat(itemsPerma10.s_10_9)]
          ]"
          :labels="[
            itemsPerma.nom_stat_1,
            itemsPerma.nom_stat_2,
            itemsPerma.nom_stat_3
          ]"
          :colors="[
            'grey_perma1',
            'grey_perma2',
            'grey_perma3'
          ]"
          :whiteText="true"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Les pertes des surfaces artificialisées 
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <Donut :secondMap="secondMap" :dataLoss="itemsLoss" :millesime1="millesime1" :millesime2="millesime2"></Donut>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Les gains des surfaces artificialisées 
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <DonutWin :secondMap="secondMap" :dataLoss="itemsWin" :millesime1="millesime1" :millesime2="millesime2"></DonutWin>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Trajectoires de l'occupation du sol vers des territoires artificialisés
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <v-row dense>
            <v-col>
              <p class="pb-0 mb-0"><b>ENTRE {{ millesime2 }} ET {{ millesime1 }}</b> <br/> (flux proportionnels en ha)</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-weight: bold">PROVENANCE</span>
            </v-col>
            <v-col>
              <span style="font-weight: bold">NOUVELLE OCCUPATION DU SOL</span>
            </v-col>
          </v-row>
          <Sankey :secondMap="secondMap" :data="dataSankey"></Sankey>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-container>
</template>

<script>
import MultiFrieze from "@/components/Explore/MultiFrieze.vue";
import Donut from "@/components/Explore/steppers/donut/Donut.vue"
import DonutWin from "@/components/Explore/steppers/donut/DonutWin.vue"
import Sankey from "@/components/Explore/steppers/sankey/Sankey.vue"
import axios from 'axios'
export default {
  name: "Step2",
  components: {
    Donut,
    DonutWin,
    MultiFrieze,
    Sankey
  },
  props: {
    city: Object,
    cityType: String,
    mapId: {
      type: Number,
      default: 1
    },
    millesime1: {
      type: String || Number
    },
    millesime2: {
      type: String || Number
    }
  },
  data () {
    return {
      items: [],
      items10: [],
      itemsUrba: [],
      itemsUrba10: [],
      itemsHabitat: [],
      itemsHabitat10: [],
      itemsActivites: [],
      itemsActivites10: [],
      itemsPerma: [],
      itemsPerma10: [],
      itemsLoss: [],
      itemsWin: [],
      dataSankey: null
    }
  },
  computed: {
    initEnd () {
      return this.items.length !== 0
    },
    secondMap () {
      return this.mapId > 1
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      var data
      if (this.cityType === "commune") {
        data = { "code_admin": this.city.id }
        this.request_city(data)
      } else if (this.cityType === "epci") {
        data = { "code_admin": this.city.id }
        this.request_epci(data)
      } else if (this.cityType === "pnr") {
        data = { "code_admin": this.city.id }
        this.request_pnr(data)
      } else if (this.cityType === "scot") {
        data = { "code_admin": this.city.id }
        this.request_scot(data)
      } else if (this.cityType === "dept") {
        data = { "code_admin": this.city.id }
        this.request_dept(data)
      } else if (this.cityType === "region") {
        data = { "code_admin": "44" }
        this.request_region(data)
      }
    },
    request_city (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommarti2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommarti2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommurba2019stat', data)
      .then(function (response) {
        self.itemsUrba = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommurba2010stat', data)
      .then(function (response) {
        self.itemsUrba10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommhabitat2019stat', data)
      .then(function (response) {
        self.itemsHabitat = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommhabitat2010stat', data)
      .then(function (response) {
        self.itemsHabitat10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommactivites2019stat', data)
      .then(function (response) {
        self.itemsActivites = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommactivites2010stat', data)
      .then(function (response) {
        self.itemsActivites10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommperma2019stat', data)
      .then(function (response) {
        self.itemsPerma = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommperma2010stat', data)
      .then(function (response) {
        self.itemsPerma10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommunesmutaartistat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommunespertesartistat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommunesgainsartistat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
    },
    request_dept (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptarti2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptarti2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepturba2019stat', data)
      .then(function (response) {
        self.itemsUrba = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepturba2010stat', data)
      .then(function (response) {
        self.itemsUrba10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepthabitat2019stat', data)
      .then(function (response) {
        self.itemsHabitat = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepthabitat2010stat', data)
      .then(function (response) {
        self.itemsHabitat10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptactivites2019stat', data)
      .then(function (response) {
        self.itemsActivites = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptactivites2010stat', data)
      .then(function (response) {
        self.itemsActivites10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptperma2019stat', data)
      .then(function (response) {
        self.itemsPerma = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptperma2010stat', data)
      .then(function (response) {
        self.itemsPerma10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepartementsmutaartistat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepartementspertesartistat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepartementsgainsartistat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
    },
    request_epci (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciarti2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciarti2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciurba2019stat', data)
      .then(function (response) {
        self.itemsUrba = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciurba2010stat', data)
      .then(function (response) {
        self.itemsUrba10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcihabitat2019stat', data)
      .then(function (response) {
        self.itemsHabitat = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcihabitat2010stat', data)
      .then(function (response) {
        self.itemsHabitat10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciactivites2019stat', data)
      .then(function (response) {
        self.itemsActivites = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciactivites2010stat', data)
      .then(function (response) {
        self.itemsActivites10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciperma2019stat', data)
      .then(function (response) {
        self.itemsPerma = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciperma2010stat', data)
      .then(function (response) {
        self.itemsPerma10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcimutaartistat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcipertesartistat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcigainsartistat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
    },
    request_region (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionarti2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionarti2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionurba2019stat', data)
      .then(function (response) {
        self.itemsUrba = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionurba2010stat', data)
      .then(function (response) {
        self.itemsUrba10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionhabitat2019stat', data)
      .then(function (response) {
        self.itemsHabitat = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionhabitat2010stat', data)
      .then(function (response) {
        self.itemsHabitat10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionactivites2019stat', data)
      .then(function (response) {
        self.itemsActivites = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionactivites2010stat', data)
      .then(function (response) {
        self.itemsActivites10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionperma2019stat', data)
      .then(function (response) {
        self.itemsPerma = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionperma2010stat', data)
      .then(function (response) {
        self.itemsPerma10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionmutaartistat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionpertesartistat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregiongainsartistat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
    },
    request_scot (data) {
      var self = this
      data = { "code_admin": data.code_admin.toString() }
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotarti2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotarti2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscoturba2019stat', data)
      .then(function (response) {
        self.itemsUrba = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscoturba2010stat', data)
      .then(function (response) {
        self.itemsUrba10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscothabitat2019stat', data)
      .then(function (response) {
        self.itemsHabitat = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscothabitat2010stat', data)
      .then(function (response) {
        self.itemsHabitat10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotactivites2019stat', data)
      .then(function (response) {
        self.itemsActivites = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotactivites2010stat', data)
      .then(function (response) {
        self.itemsActivites10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotperma2019stat', data)
      .then(function (response) {
        self.itemsPerma = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotperma2010stat', data)
      .then(function (response) {
        self.itemsPerma10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotmutaartistat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotpertesartistat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotgainsartistat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
    },
    request_pnr (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrarti2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrarti2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrurba2019stat', data)
      .then(function (response) {
        self.itemsUrba = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrurba2010stat', data)
      .then(function (response) {
        self.itemsUrba10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrhabitat2019stat', data)
      .then(function (response) {
        self.itemsHabitat = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrhabitat2010stat', data)
      .then(function (response) {
        self.itemsHabitat10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnractivites2019stat', data)
      .then(function (response) {
        self.itemsActivites = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnractivites2010stat', data)
      .then(function (response) {
        self.itemsActivites10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrperma2019stat', data)
      .then(function (response) {
        self.itemsPerma = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrperma2010stat', data)
      .then(function (response) {
        self.itemsPerma10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrmutaartistat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrpertesartistat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrgainsartistat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
    }
  },
  watch: {
    city () {
      this.init()
    },
    cityType () {
      this.init()
    }
  }
}
</script>