<template>
  <v-container>
    <v-row>
      <v-col cols="2">
      </v-col>
      <v-col cols="3">
      </v-col>
      <v-col>
        <v-row dense>
          <span style="color: #4473c5; font-weight: bold; font-size: 0.85vw">{{ year1 }}</span>
        </v-row>
      </v-col>
      <v-col>
        <v-row dense>
          <span style="color: #4473c5; font-weight: bold; font-size: 0.85vw">Depuis {{ year2 }}</span>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-for="(item, i) in items" :key="i">
      <v-col cols="2" class="d-flex justify-end" align-self="center">
        <v-icon :color="item.color" size="56">{{ item.img }}</v-icon>
      </v-col>
      <v-col cols="3" align-self="center">
        <span :style="'font-weight: bold; font-size: 0.8vw; color: ' + item.color">{{ item.description }}</span>
      </v-col>
      <v-col v-if="item.tx !== null">
        <v-row dense>
          <span style="font-weight: bold; color: black; font-size: 0.85vw">{{ item.tx }} %</span>
        </v-row>
        <v-row dense style="color: #969696; font-size: 0.85vw">
          <span>{{ formatNumber(item.number) }} {{ item.unit }}</span>
        </v-row>
      </v-col>
      <v-col v-else>
        <v-row dense>
          <span style="font-weight: bold; color: black; font-size: 0.85vw">Non Communiqué</span>
        </v-row>
        <v-row dense style="color: #969696; font-size: 0.85vw">
          <span>Non Communiqué</span>
        </v-row>
      </v-col>

      <v-col v-if="item.subTx !== null">
        <v-row dense>
          <span :style="item.subTx !== null ? (item.subTx.charAt(0) === '+' ? 'font-weight: bold; color: #ca9d49; font-size: 0.85vw' : 'font-weight: bold; color: #4a92e4; font-size: 0.85vw') : 'font-weight: bold; color: #4a92e4; font-size: 0.85vw'">{{ item.subTx }} %</span>
        </v-row>
        <v-row style="font-size: 0.85vw; color: #969696">
          {{ formatNumber(item.subNumber) }} ha
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MainStat",
  props: {
    items: {
      type: Array || null,
    },
    year1: {
      type: String
    },
    year2: {
      type: String
    },
  },
  methods: {
    formatNumber(value) {
      if (value !== null && value !== undefined) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return ''
      }
    }
  }
}
</script>