var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"friezes"}},[_c('v-expansion-panels',{attrs:{"accordion":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"bold","background-color":"#4473c5","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Les terres agricoles ")]),(_vm.initEnd)?_c('v-expansion-panel-content',[_c('MultiFrieze',{attrs:{"dates":[_vm.items.annee_n, _vm.items.annee_nm1],"data":[
            [parseFloat(_vm.items.tx_19_1), parseFloat(_vm.items.tx_19_2), parseFloat(_vm.items.tx_19_3), parseFloat(_vm.items.tx_19_4), parseFloat(_vm.items.tx_19_5), parseFloat(_vm.items.tx_19_6), parseFloat(_vm.items.tx_19_7)],
            [parseFloat(_vm.items10.tx_10_1), parseFloat(_vm.items10.tx_10_2), parseFloat(_vm.items10.tx_10_3), parseFloat(_vm.items10.tx_10_4), parseFloat(_vm.items10.tx_10_5), parseFloat(_vm.items10.tx_10_6), parseFloat(_vm.items10.tx_10_7)]
          ],"tooltipData":[
            [parseFloat(_vm.items.s_19_1), parseFloat(_vm.items.s_19_2), parseFloat(_vm.items.s_19_3), parseFloat(_vm.items.s_19_4), parseFloat(_vm.items.s_19_5), parseFloat(_vm.items.s_19_6), parseFloat(_vm.items.s_19_7)],
            [parseFloat(_vm.items10.s_10_1), parseFloat(_vm.items10.s_10_2), parseFloat(_vm.items10.s_10_3), parseFloat(_vm.items10.s_10_4), parseFloat(_vm.items10.s_10_5), parseFloat(_vm.items10.s_10_6), parseFloat(_vm.items10.s_10_7)]
          ],"labels":[
            _vm.items.nom_stat_1 + ' (' + _vm.items.s_19_1 + ' ha en 2019) (' + _vm.items10.s_10_1 + ' ha en 2010)',
            _vm.items.nom_stat_2 + ' (' + _vm.items.s_19_2 + ' ha en 2019) (' + _vm.items10.s_10_2 + ' ha en 2010)',
            _vm.items.nom_stat_3 + ' (' + _vm.items.s_19_3 + ' ha en 2019) (' + _vm.items10.s_10_3 + ' ha en 2010)',
            _vm.items.nom_stat_4 + ' (' + _vm.items.s_19_4 + ' ha en 2019) (' + _vm.items10.s_10_4 + ' ha en 2010)',
            _vm.items.nom_stat_5 + ' (' + _vm.items.s_19_5 + ' ha en 2019) (' + _vm.items10.s_10_5 + ' ha en 2010)',
            _vm.items.nom_stat_6 + ' (' + _vm.items.s_19_6 + ' ha en 2019) (' + _vm.items10.s_10_6 + ' ha en 2010)',
            _vm.items.nom_stat_7 + ' (' + _vm.items.s_19_7 + ' ha en 2019) (' + _vm.items10.s_10_7 + ' ha en 2010)'
          ],"colors":[
            'yellow_agri1',
            'yellow_agri2',
            'pink_agri1',
            'pink_agri2',
            'pink_agri3',
            'green_agri',
            'green_agri2'
          ]}})],1):_vm._e()],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"bold","background-color":"#4473c5","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Les pertes des surfaces agricoles ")]),(_vm.initEnd)?_c('v-expansion-panel-content',[_c('Donut',{attrs:{"secondMap":_vm.secondMap,"dataLoss":_vm.itemsLoss,"millesime1":_vm.millesime1,"millesime2":_vm.millesime2}})],1):_vm._e()],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"bold","background-color":"#4473c5","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Les gains des surfaces agricoles ")]),(_vm.initEnd)?_c('v-expansion-panel-content',[_c('DonutWin',{attrs:{"secondMap":_vm.secondMap,"dataLoss":_vm.itemsWin,"millesime1":_vm.millesime1,"millesime2":_vm.millesime2}})],1):_vm._e()],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"bold","background-color":"#4473c5","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Trajectoires de l'occupation du sol vers des territoires agricoles ")]),(_vm.initEnd)?_c('v-expansion-panel-content',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('p',{staticClass:"pb-0 mb-0"},[_c('b',[_vm._v("ENTRE "+_vm._s(_vm.millesime2)+" ET "+_vm._s(_vm.millesime1))]),_vm._v(" "),_c('br'),_vm._v(" (flux proportionnels en ha)")])])],1),_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("PROVENANCE")])]),_c('v-col',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("NOUVELLE OCCUPATION DU SOL")])])],1),_c('Sankey',{attrs:{"secondMap":_vm.secondMap,"data":_vm.dataSankey}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }