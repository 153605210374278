<template>
  <v-container light>
    <v-expansion-panels accordion light>
      <v-expansion-panel v-for="item in items" :key="item.id_stat">
        <v-expansion-panel-header style="font-weight: bold">{{ item.nom_stat }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <v-row>
              <v-col>
                2019 :
              </v-col>
              <v-col>
                <span style="font-weight: bold">{{item.s_19}}</span> ha
              </v-col>
              <v-col>
                <span style="font-weight: bold">{{ item.tx_19 }}</span>% <span style="font-size: small">(du territoire)</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                2010 :
              </v-col>
              <v-col>
                <span style="font-weight: bold">{{item.s_10}}</span> ha
              </v-col>
              <v-col>
                <span style="font-weight: bold">{{ item.tx_10 }}</span>% <span style="font-size: small">(du territoire)</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Evolution 2010-2019 :
              </v-col>
              <v-col>
                <span style="font-weight: bold">{{item.evo}}</span> ha
              </v-col>
              <v-col>
                <span style="font-weight: bold;">{{ item.var }}</span>% <span style="font-size: small">(du poste)</span>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'IncludedSteppers',
  props: {
    city: Object,
    cityType: String,
    level: Number
  },
  data () {
    return {
      items: null,
      stepNumber: undefined
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      var data
      if (this.cityType === "commune") {
        data = { "code_admin": this.city.id }
        this.request_city(data)
        // axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocst', data)
      } else if (this.cityType === "epci") {
        data = { "code_admin": this.city.id }
        this.request_epci(data)
      } else if (this.cityType === "pnr") {
        data = { "code_admin": this.city.id }
        this.request_pnr(data)
      } else if (this.cityType === "scot") {
        data = { "code_admin": this.city.id }
        this.request_scot(data)
      } else if (this.cityType === "dept") {
        data = { "code_admin": this.city.id }
        this.request_dept(data)
      } else if (this.cityType === "region") {
        data = { "code_admin": '44' }
        this.request_region(data)
      }
    },
    request_city (data) {
      var self = this
      if (this.level === 1) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedcommstat', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 2) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedcommstat2', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 3) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedcommstat3', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 4) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedcommstat4', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 5) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedcommstat5', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      }
    },
    request_dept (data) {
      var self = this
      if (this.level === 1) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvanceddeptstat', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 2) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvanceddeptstat2', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 3) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvanceddeptstat3', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 4) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvanceddeptstat4', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 5) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvanceddeptstat5', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      }
    },
    request_epci (data) {
      var self = this
      if (this.level === 1) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedepcistat', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 2) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedepcistat2', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 3) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedepcistat3', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 4) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedepcistat4', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 5) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedepcistat5', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      }
    },
    request_region (data) {
      var self = this
      if (this.level === 1) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedregionstat', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 2) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedregionstat2', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 3) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedregionstat3', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 4) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedregionstat4', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 5) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedregionstat5', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      }
    },
    request_scot (data) {
      var self = this
      var dataToPass = { 'code_admin': data.code_admin.toString() }
      if (this.level === 1) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedscotstat', dataToPass)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 2) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedscotstat2', dataToPass)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 3) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedscotstat3', dataToPass)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 4) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedscotstat4', dataToPass)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 5) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedscotstat5', dataToPass)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      }
    },
    request_pnr (data) {
      var self = this
      if (this.level === 1) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedpnrstat', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 2) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedpnrstat2', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 3) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedpnrstat3', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 4) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedpnrstat4', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      } else if (this.level === 5) {
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularadvancedpnrstat5', data)
        .then(function (response) {
          self.items = response.data
          self.sortArray()
        })
      }
    },
    sortArray() {
      this.items.sort((a, b) => (a.nom_stat > b.nom_stat) ? 1 : (b.nom_stat > a.nom_stat) ? -1 : 0)
    }
  },
  watch: {
    city () {
      this.init()
    },
    cityType () {
      this.init()
    },
    /* items (newVal) {
      newVal.sort((a, b) => (a.nom_stat > b.nom_stat) ? 1 : (b.nom_stat > a.nom_stat) ? -1 : 0)
    } */
  }
}
</script>