var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"friezes"}},[_c('v-expansion-panels',{attrs:{"accordion":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"bold","background-color":"#4473c5","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Les milieux humides ")]),(_vm.initEnd)?_c('v-expansion-panel-content',[_c('MultiFrieze',{attrs:{"dates":[_vm.items.annee_n, _vm.items.annee_nm1],"data":[
            [parseFloat(_vm.items.tx_19_1), parseFloat(_vm.items.tx_19_2)],
            [parseFloat(_vm.items10.tx_10_1), parseFloat(_vm.items10.tx_10_2)]
          ],"tooltipData":[
            [parseFloat(_vm.items.s_19_1), parseFloat(_vm.items.s_19_2)],
            [parseFloat(_vm.items10.s_10_1), parseFloat(_vm.items10.s_10_2)]
          ],"labels":[
            _vm.items.nom_stat_1 + ' (' + _vm.items.s_19_1 + ' ha en 2019) (' + _vm.items10.s_10_1 + ' ha en 2010)',
            _vm.items.nom_stat_2 + ' (' + _vm.items.s_19_2 + ' ha en 2019) (' + _vm.items10.s_10_2 + ' ha en 2010)'
          ],"colors":[
            'green_zh1',
            'green_zh2'
          ]}})],1):_vm._e()],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"bold","background-color":"#4473c5","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Les pertes des milieux humides ")]),(_vm.initEnd)?_c('v-expansion-panel-content',[_c('Donut',{attrs:{"secondMap":_vm.secondMap,"dataLoss":_vm.itemsLoss,"millesime1":_vm.millesime1,"millesime2":_vm.millesime2}})],1):_vm._e()],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"bold","background-color":"#4473c5","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Les gains des milieux humides ")]),(_vm.initEnd)?_c('v-expansion-panel-content',[_c('DonutWin',{attrs:{"secondMap":_vm.secondMap,"dataLoss":_vm.itemsWin,"millesime1":_vm.millesime1,"millesime2":_vm.millesime2}})],1):_vm._e()],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"font-weight":"bold","background-color":"#4473c5","color":"white"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $expand ")])]},proxy:true}])},[_vm._v("Trajectoires de l'occupation du sol vers des milieux humides ")]),_c('v-expansion-panel-content',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('p',{staticClass:"pb-0 mb-0"},[_c('b',[_vm._v("ENTRE "+_vm._s(_vm.millesime2)+" ET "+_vm._s(_vm.millesime1))]),_vm._v(" "),_c('br'),_vm._v(" (flux proportionnels en ha)")])])],1),_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("PROVENANCE")])]),_c('v-col',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("NOUVELLE OCCUPATION DU SOL")])])],1),_c('Sankey',{attrs:{"secondMap":_vm.secondMap,"data":_vm.dataSankey}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }