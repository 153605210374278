<template>
  <div>
    <h2 class="ml-5">Mutation vers :</h2>
    <div class="chart-style" :id="secondMap ? 'donutdiv2' : 'donutdiv'"></div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default {
  name: 'Donut',
  props: {
    dataLoss: {
      type: Object
    },
    secondMap: {
      type: Boolean,
      default: false
    },
    millesime1: {
      type: String || Number
    },
    millesime2: {
      type: String || Number
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (this.chart !== null){
        this.chart.dispose()
      }
      var self = this
      am4core.ready(function() {

      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end

      var container
      if (self.secondMap) {
        container = "donutdiv2"
      } else {
        container = "donutdiv"
      }

      // Create chart instance
      self.chart = am4core.create(container, am4charts.PieChart);
      self.chart.startAngle = 160;
      self.chart.endAngle = 380;
      self.chart.height = 400;

      // Let's cut a hole in our Pie chart the size of 40% the radius
      self.chart.innerRadius = am4core.percent(40);

      // Add data
      self.chart.data = [{
        "country": self.dataLoss.nom_stat_2,
        "bottles": self.dataLoss.s_2,
        "nodeColor": self.dataLoss.color_2
      }, {
        "country": self.dataLoss.nom_stat_3,
        "bottles": self.dataLoss.s_3,
        "nodeColor": self.dataLoss.color_3
      }, {
        "country": self.dataLoss.nom_stat_4,
        "bottles": self.dataLoss.s_4,
        "nodeColor": self.dataLoss.color_4
      }, {
        "country": self.dataLoss.nom_stat_5,
        "bottles": self.dataLoss.s_5,
        "nodeColor": self.dataLoss.color_5
      }, {
        "country": self.dataLoss.nom_stat_6,
        "bottles": self.dataLoss.s_6,
        "nodeColor": self.dataLoss.color_6
      }, {
        "country": self.dataLoss.nom_stat_7,
        "bottles": self.dataLoss.s_7,
        "nodeColor": self.dataLoss.color_7
      }, {
        "country": self.dataLoss.nom_stat_8,
        "bottles": self.dataLoss.s_8,
        "nodeColor": self.dataLoss.color_8
      }, {
        "country": self.dataLoss.nom_stat_9,
        "bottles": self.dataLoss.s_9,
        "nodeColor": self.dataLoss.color_9
      }, {
        "country": self.dataLoss.nom_stat_10,
        "bottles": self.dataLoss.s_10,
        "nodeColor": self.dataLoss.color_10
      }, {
        "country": self.dataLoss.nom_stat_11,
        "bottles": self.dataLoss.s_11,
        "nodeColor": self.dataLoss.color_11
      }, {
        "country": self.dataLoss.nom_stat_12,
        "bottles": self.dataLoss.s_12,
        "nodeColor": self.dataLoss.color_12
      }, {
        "country": self.dataLoss.nom_stat_13,
        "bottles": self.dataLoss.s_13,
        "nodeColor": self.dataLoss.color_13
      }, {
        "country": self.dataLoss.nom_stat_14,
        "bottles": self.dataLoss.s_14,
        "nodeColor": self.dataLoss.color_14
      }, {
        "country": self.dataLoss.nom_stat_15,
        "bottles": self.dataLoss.s_15,
        "nodeColor": self.dataLoss.color_15
      }];

      // Add and configure Series
      var pieSeries = self.chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "country";
      pieSeries.slices.template.stroke = new am4core.InterfaceColorSet().getFor("background");
      pieSeries.slices.template.strokeWidth = 1;
      pieSeries.slices.template.strokeOpacity = 1;

      // Disabling labels and ticks on inner circle
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;

      // Disable sliding out of slices
      pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
      pieSeries.slices.template.states.getKey("hover").properties.scale = 1;
      pieSeries.radius = am4core.percent(40);
      pieSeries.innerRadius = am4core.percent(30);

      var cs = pieSeries.colors;
      cs.list = [am4core.color(new am4core.ColorSet().getIndex(0))];

      cs.stepOptions = {
        lightness: -0.05,
        hue: 0
      };
      cs.wrap = false;


      // Add second series
      var pieSeries2 = self.chart.series.push(new am4charts.PieSeries());
      pieSeries2.dataFields.value = "bottles";
      pieSeries2.dataFields.category = "country";
      pieSeries2.slices.template.propertyFields.fill = "nodeColor";
      pieSeries2.slices.template.stroke = new am4core.InterfaceColorSet().getFor("background");
      pieSeries2.slices.template.strokeWidth = 1;
      pieSeries2.slices.template.strokeOpacity = 1;
      pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0.05;
      pieSeries2.slices.template.states.getKey("hover").properties.scale = 1;

      pieSeries2.labels.template.disabled = true;
      pieSeries2.ticks.template.disabled = true;
      pieSeries2.slices.template.tooltipText = "{category}: {value.percent} %[/]\n soit {value.value} ha"


      var label = self.chart.seriesContainer.createChild(am4core.Label);
      label.textAlign = "middle";
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.adapter.add("text", function(){
        return "[font-size:18px] Total des " + self.dataLoss.nom_stat_1 + "[/]\n[font-size:18px] entre " + self.millesime2 + " et " + self.millesime1 + "[/]:\n\n[bold font-size:30px]" + self.dataLoss.s_1 + " ha[/]";
      })

      }); // end am4core.ready()
    }
  },
  watch: {
    dataLoss () {
      this.init()
    }
  }
}
</script>

<style scoped>
.chart-style {
  width: 100%;
  height: 400px;
}
</style>