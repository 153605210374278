<template>
  <v-container @mouseover="updateFriezes()">
    <div v-for="(aDate, indexDate) in dates" :key="indexDate" class="mt-1">
      <h3>{{ aDate }} (%)</h3>

      <v-container>
        <v-row
          id="thefrieze"
          ref="thefrieze"
          style="outline: black solid 1px; min-height: 24px"
          @mouseenter="showDetails = true"
          @mouseleave="showDetails = false"
        >
          <div
            v-for="(aData, indexData) in data[indexDate]"
            :key="indexData"
            ref="div_eADCommerciale"
            class="text-center"
            :class="theColors[indexData]"
            style="transition: width 0.25s; overflow: hidden"
            :style="
              showDetails
                ? 'width: ' +
                  friezesWidths[indexDate] / data[indexDate].length + // proportional width div
                  'px'
                : 'width: ' +
                  (aData / totValues[indexDate]) * friezesWidths[indexDate] + // width div matches data
                  'px;'
            "
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <small
                  class="text-center"
                  style="color: black; text-shadow: 0px 0px 8px white"
                  v-bind="attrs"
                  v-on="on"
                  v-if="!whiteText"
                >
                  <!-- Affiche la donnée si l'utilisateur passe au dessus, sinon l'affiche si il y a de la place (div width > 14px) -->
                  {{
                    showDetails
                      ? aData
                      : (aData / totValues[indexDate]) * friezesWidths[indexDate] >
                        14
                      ? aData
                      : ""
                  }}
                </small>
              </template>
              <span>{{ tooltipData[indexDate][indexData] }} ha</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <small
                  class="text-center"
                  style="color: white; text-shadow: 0px 0px 8px white"
                  v-bind="attrs"
                  v-on="on"
                  v-if="whiteText"
                >
                  <!-- Affiche la donnée si l'utilisateur passe au dessus, sinon l'affiche si il y a de la place (div width > 14px) -->
                  {{
                    showDetails
                      ? aData
                      : (aData / totValues[indexDate]) * friezesWidths[indexDate] >
                        14
                      ? aData
                      : ""
                  }}
                </small>
              </template>
              <span>{{ tooltipData[indexDate][indexData] }} ha</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-container>
    </div>

    <br />
    <v-scroll-y-transition>
      <v-card v-if="gotLabel">
        <v-card-text class="pa-2"> Légende : </v-card-text>
        <small>
          <div v-for="(aLabel, index) in labels" :key="index">
            <v-icon :class="theColors[index] + '--text'">mdi-square</v-icon> :
            {{ aLabel }}
            <br />
          </div>
        </small>
      </v-card>
    </v-scroll-y-transition>
  </v-container>
</template>

<script>
export default {
  props: {
    /** @type { Array } List of dates (one for each frieze). */
    dates: Array,
    /** @type { Array( number[] ) } List of list of data for each frieze. e.g. : [ [10,20,5], [25,10,40] ] */
    data: Array,
    /** @type { String[] } List of labels to go with the data */
    labels: Array,
    /** @type { String[] } Optional liste of custom colors for the frieze. The format must be Vuetify color names. */
    colors: null,
    /** @type { String[] } Optional list of custorm colors for the frieze (accept hexa value) */
    tooltipData: Array,
    whiteText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      /** Default color list */
      theColors: [
        "red",
        "amber",
        "green",
        "blue",
        "orange",
        "cyan",
        "brown",
        "purple",
        "indigo",
        "teal",
        "light-green",
        "deep-orange",
        "lime",
      ],

      friezesWidths: [],
      showDetails: false,
    };
  },
  computed: {
    /**
     * @return { number[] } the sum of all the data except the date.
     */
    totValues() {
      let retValues = [];
      this.data.forEach((aDataList) => {
        retValues.push(aDataList.reduce((n1, n2) => n1 + n2));
      });
      return retValues;
    },
    gotLabel() {
      return this.labels !== undefined && this.labels.length !== 0;
    },
    gotCustomColors() {
      return (
        this.customColor !== undefined &&
        this.customColor !== null &&
        this.customColor.length !== 0
      );
    },
  },
  methods: {
    /**
     * Useful in case the client resize its window.
     * There was difficulties to use a computed, so the friezes are reactualised each time this method is called.
     */
    updateFriezes() {
      for (let i = 0; i < this.$refs.thefrieze.length; i++) {
        this.friezesWidths[i] = Math.ceil(
          this.$refs.thefrieze[i].clientWidth - 1
        ); // the Math.ceil & -1  are here to prevent the colored divs to exceed the tot width thus being aesthetically bugged.
      }
      this.friezesWidths = JSON.parse(JSON.stringify(this.friezesWidths));
    },
  },
  mounted() {
    // Useful in case the client resize its window.
    setInterval(() => {
      this.updateFriezes();
    }, 1000);

    // if the given color list given in props matches the length of labels
    if (this.colors && this.colors.length >= this.labels.length)
      this.theColors = this.colors;
  },
};
</script>
<style>
</style>