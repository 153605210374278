<template>
  <v-container>
    <v-expansion-panels accordion>
      <v-expansion-panel style="background-color: #4473c5; color: white">
        <v-expansion-panel-header style="font-weight: bold;" disabled>
          Etat et Evolution de l'occupation du sol en 2010 et 2019 par niveau de la nomenclature
           <template v-slot:actions>
            <v-icon color="white">
            </v-icon>
          </template>
        </v-expansion-panel-header>
      </v-expansion-panel>
      <v-expansion-panel style="background-color: #4473c5; color: white">
        <v-expansion-panel-header>
          Niveau 1
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <IncludedSteppers :city="city" :cityType="cityType" :level="1"></IncludedSteppers>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel style="background-color: #4473c5; color: white">
        <v-expansion-panel-header>
          Niveau 2
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <IncludedSteppers :city="city" :cityType="cityType" :level="2"></IncludedSteppers>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel style="background-color: #4473c5; color: white">
        <v-expansion-panel-header>
          Niveau 3
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <IncludedSteppers :city="city" :cityType="cityType" :level="3"></IncludedSteppers>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel style="background-color: #4473c5; color: white">
        <v-expansion-panel-header>
          Niveau 4
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <IncludedSteppers :city="city" :cityType="cityType" :level="4"></IncludedSteppers>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel style="background-color: #4473c5; color: white">
        <v-expansion-panel-header>
          Niveau 5
          <template v-slot:actions>
            <v-icon color="white">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <IncludedSteppers :city="city" :cityType="cityType" :level="5"></IncludedSteppers>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import IncludedSteppers from '@/components/Explore/steppers/IncludedSteppers/IncludedSteppers'

export default {
  name: "Step1",
  components: {
    IncludedSteppers
  },
  props: {
    city: {
      type: Object
    },
    cityType: {
      type: String
    },
    mapId: {
      type: Number,
      default: 1
    },
    millesime1: {
      type: String || Number
    },
    millesime2: {
      type: String || Number
    }
  },
  computed: {
    secondMap () {
      return this.mapId > 1
    }
  }
}
</script>