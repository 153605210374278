<template>
  <!-- Analyse dialog is used on Compare & Explore pages -->
  <v-dialog width="80%" class="analyse-dialog-div" v-model="showDialog">
    <!-- White div on the center of the dialog -->
    <v-card class="analyse-dialog-card">
      <p class="analyse-dialog-title">Analyses disponibles</p>
      <!-- Group of all the radio buttons -->
      <v-radio-group :mandatory="false" v-model="selected">
        <v-row>
          <v-col>
            <p class="analyse-dialog-subtitle">Statistiques Générales</p>
            <v-radio class="mb-7" v-for="(item, i) in radiosGlobals" :key="i">
              <template v-slot:label
                ><span v-html="item.label"></span
              ></template>
            </v-radio>
          </v-col>
          <v-col>
            <p class="analyse-dialog-subtitle">Statistiques Complémentaires</p>
            <v-radio class="mb-7" v-for="(item, i) in radios" :key="i">
              <template v-slot:label
                ><span v-html="item.label"></span
              ></template>
            </v-radio>
          </v-col>
        </v-row>
      </v-radio-group>
      <!-- Submit & Cancel button, which close the dialog -->
      <v-card-actions class="analyse-dialog-btn-grid">
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="validate"
          >Valider</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AnalyseDialog",
  props: {
    currentCity: {
      type: Object
    },
    // Dialog is displayed when dialog is true, and hidden if not
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      selected: 0,
      radiosGlobals: [
        {
          value: 0,
          label: "Chiffres clés <b>des grandes composantes de l'occupation du sol</b>",
          section: 1,
          subLabel: [],
        },
        {
          value: 1,
          label:
            "<b>Etat et évolution de l’occupation du sol en 2010 et 2019</b> par niveau de la nomenclature (1 à 5) pour les deux millésimes",
          section: 1,
          subLabel: [],
        },
      ],
      radios: [
        {
          value: 2,
          label: "Analyses complémentaires sur les <b>territoires artificialisés</b> pour les deux millésimes",
          section: 2
        },
        {
          value: 3,
          label: "Analyses complémentaires sur les <b>territoires agricoles</b> pour les deux millésimes",
          section: 2
        },
        {
          value: 4,
          label:
            "Analyses complémentaires sur les <b>territoires forestiers et pré-forestiers</b> pour les deux millésimes",
          section: 2
        },
        {
          value: 5,
          label: "Analyses complémentaires sur les <b>milieux humides</b> pour les deux millésimes",
          section: 2
        },
        {
          value: 6,
          label: "Analyses complémentaires sur les <b>surfaces en eau</b> pour les deux millésimes",
          section: 2
        },
      ],
    };
  },
  computed: {
    // change the showDialog value dynamically depending on dialog prop value
    showDialog: {
      get: function () {
        return this.dialog;
      },
      // emit UpdateDialog event when showDialog value is modified
      set: function () {
        this.$emit("updateDialog", this.selected);
      },
    },
  },
  methods: {
    validate () {
      this.showDialog = false
    },
    cancel () {
      this.selected = 0
      this.showDialog = false
    }
  }
};
</script>

<style scoped>
.analyse-dialog-subtitle {
  font-size: 18px;
  color: #4473c5;
  font-weight: bold
}
</style>