<template>
<!-- Friezes and graphs section -->
<v-container id="friezes">
  <v-expansion-panels accordion>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Les terres agricoles
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <MultiFrieze
          :dates="[items.annee_n, items.annee_nm1]"
          :data="[
            [parseFloat(items.tx_19_1), parseFloat(items.tx_19_2), parseFloat(items.tx_19_3), parseFloat(items.tx_19_4), parseFloat(items.tx_19_5), parseFloat(items.tx_19_6), parseFloat(items.tx_19_7)],
            [parseFloat(items10.tx_10_1), parseFloat(items10.tx_10_2), parseFloat(items10.tx_10_3), parseFloat(items10.tx_10_4), parseFloat(items10.tx_10_5), parseFloat(items10.tx_10_6), parseFloat(items10.tx_10_7)]
          ]"
          :tooltipData="[
            [parseFloat(items.s_19_1), parseFloat(items.s_19_2), parseFloat(items.s_19_3), parseFloat(items.s_19_4), parseFloat(items.s_19_5), parseFloat(items.s_19_6), parseFloat(items.s_19_7)],
            [parseFloat(items10.s_10_1), parseFloat(items10.s_10_2), parseFloat(items10.s_10_3), parseFloat(items10.s_10_4), parseFloat(items10.s_10_5), parseFloat(items10.s_10_6), parseFloat(items10.s_10_7)]
          ]"
          :labels="[
            items.nom_stat_1 + ' (' + items.s_19_1 + ' ha en 2019) (' + items10.s_10_1 + ' ha en 2010)',
            items.nom_stat_2 + ' (' + items.s_19_2 + ' ha en 2019) (' + items10.s_10_2 + ' ha en 2010)',
            items.nom_stat_3 + ' (' + items.s_19_3 + ' ha en 2019) (' + items10.s_10_3 + ' ha en 2010)',
            items.nom_stat_4 + ' (' + items.s_19_4 + ' ha en 2019) (' + items10.s_10_4 + ' ha en 2010)',
            items.nom_stat_5 + ' (' + items.s_19_5 + ' ha en 2019) (' + items10.s_10_5 + ' ha en 2010)',
            items.nom_stat_6 + ' (' + items.s_19_6 + ' ha en 2019) (' + items10.s_10_6 + ' ha en 2010)',
            items.nom_stat_7 + ' (' + items.s_19_7 + ' ha en 2019) (' + items10.s_10_7 + ' ha en 2010)'
          ]"
          :colors="[
            'yellow_agri1',
            'yellow_agri2',
            'pink_agri1',
            'pink_agri2',
            'pink_agri3',
            'green_agri',
            'green_agri2'
          ]"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Les pertes des surfaces agricoles
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <Donut :secondMap="secondMap" :dataLoss="itemsLoss" :millesime1="millesime1" :millesime2="millesime2"></Donut>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Les gains des surfaces agricoles
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <DonutWin :secondMap="secondMap" :dataLoss="itemsWin" :millesime1="millesime1" :millesime2="millesime2"></DonutWin>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header style="font-weight: bold; background-color: #4473c5; color: white">Trajectoires de l'occupation du sol vers des territoires agricoles
        <template v-slot:actions>
          <v-icon color="white">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="initEnd">
        <v-container>
          <v-row dense>
            <v-col>
              <p class="pb-0 mb-0"><b>ENTRE {{ millesime2 }} ET {{ millesime1 }}</b> <br/> (flux proportionnels en ha)</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span style="font-weight: bold">PROVENANCE</span>
            </v-col>
            <v-col>
              <span style="font-weight: bold">NOUVELLE OCCUPATION DU SOL</span>
            </v-col>
          </v-row>
          <Sankey :secondMap="secondMap" :data="dataSankey"></Sankey>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-container>
</template>

<script>
import MultiFrieze from "@/components/Explore/MultiFrieze.vue";
import Donut from "@/components/Explore/steppers/donut/Donut.vue"
import DonutWin from "@/components/Explore/steppers/donut/DonutWin.vue"
import Sankey from "@/components/Explore/steppers/sankey/Sankey.vue"
import axios from 'axios'
export default {
  name: "Step3",
  components: {
    Donut,
    DonutWin,
    MultiFrieze,
    Sankey
  },
  props: {
    city: Object,
    cityType: String,
    mapId: {
      type: Number,
      default: 1
    },
    millesime1: {
      type: String || Number
    },
    millesime2: {
      type: String || Number
    }
  },
  data () {
    return {
      dataSankey: null,
      items: [],
      items10: [],
      itemsLoss: [],
      itemsWin: []
    }
  },
  computed: {
    initEnd () {
      return this.items.length !== 0
    },
    secondMap () {
      return this.mapId > 1
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      var data
      if (this.cityType === "commune") {
        data = { "code_admin": this.city.id }
        this.request_city(data)
      } else if (this.cityType === "epci") {
        data = { "code_admin": this.city.id }
        this.request_epci(data)
      } else if (this.cityType === "pnr") {
        data = { "code_admin": this.city.id }
        this.request_pnr(data)
      } else if (this.cityType === "scot") {
        data = { "code_admin": this.city.id }
        this.request_scot(data)
      } else if (this.cityType === "dept") {
        data = { "code_admin": this.city.id }
        this.request_dept(data)
      } else if (this.cityType === "region") {
        data = { "code_admin": "44" }
        this.request_region(data)
      }
    },
    request_city (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommagri2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommagri2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommunespertesagristat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommunesgainsagristat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularcommunesmutaagristat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
    },
    request_region (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionagri2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionagri2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionpertesagristat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregiongainsagristat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularregionmutaagristat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
    },
    request_dept (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptagri2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptagri2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepartementspertesagristat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepartementsgainsagristat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardepartementsmutaagristat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
    },
    request_epci (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciagri2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepciagri2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcipertesagristat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcigainsagristat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcimutaagristat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
    },
    request_scot (data) {
      var self = this
      data = { "code_admin": data.code_admin.toString() }
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotagri2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotagri2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotpertesagristat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotgainsagristat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotmutaagristat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
    },
    request_pnr (data) {
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnragri2019stat', data)
      .then(function (response) {
        self.items = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnragri2010stat', data)
      .then(function (response) {
        self.items10 = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrpertesagristat', data)
      .then(function (response) {
        self.itemsLoss = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrgainsagristat', data)
      .then(function (response) {
        self.itemsWin = response.data
      })
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrmutaagristat', data)
      .then(function (response) {
        self.dataSankey = response.data
      })
    }
  },
  watch: {
    city () {
      this.init()
    },
    cityType () {
      this.init()
    }
  }
}
</script>